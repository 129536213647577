<template>
    <div class="banner">
            <div class="banner__inner">
                <div class="banner__content">
                    <h1 class="banner__heading">ПРИНИМАЕМ ЗАКАЗЫ
НА ДОСТАВКУ ЦВЕТОВ</h1>
                    <div class="banner__link-wrapper">
                        <button class="banner__link" @click="selectCategory">Выбрать букет</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    methods: {
        ...mapActions([
            'SELECT_CATEGORY',
            'GET_MORE_PRODUCTS',
            'RESET_PAGE_NUMBER',
            'RESET_SEARCH_VALUE'
        ]),
        selectCategory(){
            this.SELECT_CATEGORY('1 СЕНТЯБРЯ');
            // this.RESET_PAGE_NUMBER();
            // this.RESET_SEARCH_VALUE();
            // this.GET_MORE_PRODUCTS();
            this.$router.push('/catalog')
        }
    },
}
</script>

<style scoped>
    .banner__inner{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 700px;
        color: #fff;
        background: url(../images/sun-ban.png) no-repeat;
        background-size: cover;
        padding-right: 15px;
        padding-left: 15px;
    }
    .banner__content{
        max-width: 1190px;
        padding: 75px 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        backdrop-filter: blur(28px);
        background: rgba(13, 13, 13, 0.1);
        border-radius: 35px;
    }
    .banner__heading{
        font-size: 85px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 50px;
    }
    .banner__subheading{
        font-size: 49px;
        font-weight: 400;
        text-align: center;
        font-family: 'Aubrey Pro';
        letter-spacing: 0.06rem;
        text-shadow: 0 3px 4px rgba(141, 31, 29, 0.3);
        margin-bottom: 45px;
    }
    .banner__link-wrapper{
        background-color: #fff;
        border-radius: 9px;
        transition: all linear .2s;
    }
    .banner__link{
        font-family: 'Aubrey Pro';
        text-decoration: none;
        padding: 15px 20px;
        border: 1px solid #fff;
        /* letter-spacing: 0.06em; */
        font-size: 35px;
        line-height: 100%;
        text-transform: uppercase;
        transition: all linear .2s;
        border-radius: 9px;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14);
        background: linear-gradient(129deg, #b90f14 0%, #96211e 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .banner__link-wrapper:hover{
        background: transparent;
        /* opacity: .5; */
    }
    .banner__link:hover{
        background: #fff;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media (max-width: 992px){
        .banner__content{
            padding: 60px 80px;
        }
        .banner__heading{
            font-size: 90px;
        }
        .banner__subheading{
            font-size: 35px;
        }
        .banner__link{
            font-size: 24px;
        }
    }
    @media (max-width: 768px){
        .banner__heading{
            font-size: 38px;
        }
    }
    @media (max-width: 600px){
        .banner__inner{
            background-image: url(../images/sun-ban-mob.png);
            min-height: auto;
            min-height: 580px;
        }
        .banner__content{
            padding: 50px 20px;
        }
        .banner__heading{
            font-size: 48px;
            margin-bottom: 20px;
        }
        .banner__subheading{
            font-size: 24px;
            margin-bottom: 25px;
            line-height: 118%;
            letter-spacing: 0.06em;
        }
        .banner__link{
            font-size: 18px;
            padding: 8px 15px;
        }
    }
</style>